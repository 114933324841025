import React, { Component } from 'react';

import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { translate } from 'react-i18next';

import UIStore from '../../Stores/UIStore';
import mapStore from '../../Stores/MapStore';
import reservationStore from '../../Stores/ReservationStore';
import OptionsStore from '../../Stores/OptionsStore';

import { Col, Row } from 'reactstrap';

//eslint-disable-next-line
import LeafletSearch from 'leaflet-search';
import L from 'leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.gridlayer.googlemutant';

import PARK_TYPES from '../../constants/parkTypes';
import OBJECT_TYPES from '../../constants/objectTypes';
import styles from './assets/styles';
import iconUrls from './assets/urls';
import banner from '../../Assets/Images/banner.jpg';

import './MainMap.css';
import CityStore from '../../Stores/CityStore';

import CurrentPositionButton from '../Common/Buttons/CurrentPositionButton';

import {
  findAllParkings,
  findNearestToPoint,
  findOnly,
  findMarkerByLatLng,
} from './helpers';
import { BANNER, BANNER_URL, FOCUS_ON_MARKER } from '../../Config';
import ParkingTypeToggle from '../ParkingSection/ParkingTypeToggle';
import NavBtn from "../NavBtn/NavBtn";

@translate(['map'], { wait: true })
@observer
class MainMap extends Component {
  @observable polyLines = [];
  @observable UIStore;
  @observable searchCreated = false;

  currentObject = {
    lineColor: null,
    defaultIcon: null,
    objectId: null,
  };

  map = null;

  markerCluster = null;

  controlSearch = null;

  objectId = 1;

  constructor(props) {
    super(props);
    this.state = {
      position: [44.58883, 33.5224],
      zoom: OptionsStore.initialZoom,
      dataForInfo: null,
      height: 0,
      marker: null,
      hasError: false,
      currentPos: null,
      currentAccuracy: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!UIStore.reservationVisibility) {
      this.resetMarkerIcon();
    }
  }

  componentDidMount() {
    this.initMapStyle();
    this.initEvents();
    this.addMap();
    this.createSearch();
    this.map.on('locationfound', this.onCurrentLocationFound);
    this.map.on('locationerror', this.onCurrentLocationError);
  }

  componentDidUpdate() {
    if (this.map) {
      if (this.markerCluster) {
        this.map.removeLayer(this.markerCluster);
        this.polyLines = [];
      }

      this.createMapElements();
      if (!this.searchCreated) this.createSearch();
    }
  }

  componentWillUnmount() {
    this.removeEvents();
  }

  createSearch = () => {
    const { t, data } = this.props;
    if (data.hasOwnProperty('features'))
      if (this.markerCluster !== null) {
        let controlSearch = new L.Control.Search({
          propertyName: 'title',
          container: 'find-box',
          collapsed: false,
          layer: this.markerCluster,
          buildTip: this.customTip,
          initial: false,
          zoom: 18,
          marker: false,
          textErr: t('map:searchError'),
          firstTipSubmit: true,
          textPlaceholder: t('map:searchField'),
          filterData: function(text, records) {
            let I, iCase, regSearch, fRecords = {};
            text = text.replace(/[.*+?^${}()|[\]\\]/g, '');  //sanitize remove all special characters
            if(text==='') return [];
            text = text.replace(/[\s,]/g, '.*')
            I = this.options.initial ? '^' : '';  //search only initial text
            iCase = !this.options.casesensitive ? 'i' : undefined;
            regSearch = new RegExp(I + text, iCase);
            //TODO use .filter or .map
            for(let key in records) {
              // search only in address (first element of key)
              const splitKey = key.split('|');
              if( regSearch.test(splitKey[0]) )
                fRecords[key]= records[key];
            }
            return fRecords;
          }
        }).on('search:locationfound', (e) => {
          this.locationFoundEvent(e);
        });

        this.controlSearch = controlSearch;
        this.map.addControl(this.controlSearch);
        this.searchCreated = true;
      }
  };

  customTip(text, val) {
    const iconUrl = val.layer.options.icon.options.iconUrl;
    return `<li class='search-tip'><span class="search-tip__text">${text}</span><i class="search-tip__icon" style="background-image: url('${iconUrl}');"/></li>`;
  }

  renderAdvert() {
    if (BANNER) {
      return (
        <a className="map_banner" href={BANNER_URL} target="_blank">
          <img src={banner} alt="Мир кэшбек" />
        </a>
      );
    }

    return null;
  }

  render() {
    if (!this.state.hasError)
      return (
        <Row
          noGutters
          style={{ height: `calc(100% - ${UIStore.footerHeight}px)` }}
        >
          <Col>
            <div
              ref={(mapContainer) => (this.mapContainer = mapContainer)}
              id={'map'}
              style={{ height: '100%' }}
            />
            {this.renderAdvert()}
            <CurrentPositionButton onClick={this.handlePosition} />
            <div id={'find-box'} />
          </Col>
        </Row>
      );

    return <div>Извините, но возникла ошибка, попробуйте позже</div>;
  }

  setView = (e) => {
    this.map.setView(e, 18);
  };

  onCurrentLocationFound = (e) => {
    const { t, data } = this.props;
    const { currentPos } = this.state;

    if (this.bounds.contains(e.latlng)) {
      if (currentPos) {
        this.map.removeLayer(currentPos);
      }

      const positionMarker = L.marker(e.latlng).addTo(this.map);

      if (FOCUS_ON_MARKER === 1) {
        const parkings = findOnly('parkings', data.features);
        const nearestMarkerLatLng = findNearestToPoint(
          positionMarker,
          parkings
        );

        this.setView(nearestMarkerLatLng);
        const nearestMarker = findMarkerByLatLng(
          nearestMarkerLatLng,
          findAllParkings(this.markerCluster)
        );

        if (nearestMarker) {
          this.clickOnParkingMarker(nearestMarker);
          this.clickOnObject(nearestMarker.options.feature.properties);
        } else {
          alert(t('errors:CantFindParking'));
        }
      } else {
        this.setView(positionMarker);
      }
    } else {
      alert(t('errors:NotInCity'));
      this.map.setView(this.currentCoords, this.currentZoom);
    }
  };

  onCurrentLocationError = () => {
    const { t } = this.props;
    window.alert(t('errors:CantFindLocation'));
  };

  handlePosition = () => {
    this.currentCoords = L.latLng(this.map.getCenter());
    this.currentZoom = this.map.getZoom();
    this.map.locate({ setView: false });
  };

  addMap = () => {
    const initCenter = this.initMapCenter();
    const mapCenter = initCenter
      ? [initCenter[0], initCenter[1]]
      : this.state.position;

    const southWest = L.latLng(CityStore.mapBounds.southWest.slice());
    const northEast = L.latLng(CityStore.mapBounds.northEast.slice());

    const bounds = L.latLngBounds(northEast, southWest);

    this.bounds = bounds;

    this.map = L.map('map', {
      maxBounds: bounds,
      maxBoundsViscosity: 1.0,
      zoomControl: true,
      maxZoom: 18,
      minZoom: 13,
      zoom: OptionsStore.initialZoom,
      preferCanvas: true,
    }).setView(mapCenter, this.state.zoom);

    this.map.zoomControl.setPosition('bottomright');

    const osmUrl = CityStore.mapSource;
    const osmAttrib =
      'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors';

    if (osmUrl === 'google') {
      L.gridLayer
        .googleMutant({
          type: 'roadmap',
        })
        .addTo(this.map);
    } else {
      // eslint-disable-next-line
      const osm = new L.TileLayer(osmUrl, {
        maxZoom: 22,
        maxNativeZoom: 18,
        attribution: osmAttrib,
      }).addTo(this.map);
    }
    this.createMarkerClusterGroup();
    this.map.invalidateSize();
  };

  createMarkerClusterGroup = () => {
    this.markerCluster = L.markerClusterGroup({
      maxClusterRadius: 120,
      disableClusteringAtZoom: 17,
      spiderfyOnMaxZoom: false,
      spiderLegPolylineOptions: {
        color: '#111',
        fill: '#111',
        fillColor: '#111',
      },
      iconCreateFunction: function(cluster) {
        return L.divIcon({
          html: '<b>' + cluster.getChildCount() + '</b>',
          className: 'cluster',
          iconSize: L.point(40, 40),
        });
      },
    });
  };

  createMapElements = () => {
    const data = this.props.data;
    const status = mapStore.status;
    const map = this.map;

    this.createMarkerClusterGroup();
    map.addLayer(this.markerCluster);

    if (status === 'done' && data.features) {
      data.features.forEach((item) => {
        let stylesAndIcons = this.getStylesAndIcon(item);
        if (item) {
          if (
            item.geometry.type === 'LineString' ||
            item.geometry.type === 'Polygon'
          ) {
            const isPolyline = item.geometry.type === 'LineString';

            const geometry = isPolyline
              ? this.addPolyLine(item)
              : this.addPolygon(item);

            this.addParkingPoint(geometry, item);
          } else {
            this.addParcomatPoint(item, stylesAndIcons);
          }
        }
        return null;
      });
    }

    if (this.controlSearch) {
      const { t } = this.props;

      const input = document.querySelector(
        '.leaflet-control-search > input.search-input'
      );
      input.placeholder = t('map:searchField');
      this.controlSearch.setLayer(this.markerCluster);
    }
  };

  addParcomatPoint = (item, stylesAndIcons) => {
    const category = this.getCategory(item.properties.category);
    let address =
      item.properties.address !== '' ? item.properties.address : ' ';

    if (item.geometry && item.geometry.coordinates) {
      let marker = L.marker(new L.latLng(item.geometry.coordinates.toJS()), {
        icon: new L.Icon({
          iconSize: this.getIconSize(category),
          iconAnchor: this.getIconAnchor(category),
          popupAnchor: [1, -24],
          iconUrl: stylesAndIcons.icon,
        }),
        title: address + ' | ' + category,
        objectId: this.objectId,
        type: 'Point',
        feature: item,
      }).on('click', () => {
        this.clickOnParcomatPoint(marker);
        this.clickOnObject(item.properties);
      });
      this.markerCluster.addLayer(marker);
      this.objectId++;
    }
  };

  addPolyLine = (feature) => {
    let coordinates = this.toArray(feature.geometry.coordinates);
    const category = feature.properties.category;
    let style = this.getStylesAndIcon(feature).style;
    let polyLine = L.polyline(coordinates, {
      color: style.color,
      title: ' ',
      objectId: this.objectId,
      type: 'PolyLine',
      weight: style.weight,
      feature: feature,
    })
      .on('click', () => {
        this.clickOnParkingLine(
          polyLine,
          this.getStylesAndIcon(feature).icon,
          category
        );
        this.clickOnObject(feature.properties);
      })
      .addTo(this.markerCluster);
    this.objectId++;

    return polyLine;
  };

  addPolygon = (feature) => {
    let coordinates = this.toArray(feature.geometry.coordinates);
    const category = feature.properties.category;
    let style = this.getStylesAndIcon(feature).style;
    let polygone = L.polygon(coordinates, {
      color: style.color,
      title: ' ',
      type: 'Polygon',
      objectId: this.objectId,
      weight: style.weight,
      feature: feature,
    })
      .on('click', () => {
        this.clickOnParkingLine(
          polygone,
          this.getStylesAndIcon(feature).icon,
          category
        );
        this.clickOnObject(feature.properties);
      })
      .addTo(this.markerCluster);
    this.objectId++;

    return polygone;
  };

  addParkingPoint = (polyLine, feature) => {
    let centerCoordinates = polyLine.getCenter();
    let iconUrl = this.getStylesAndIcon(feature).icon;
    const category = feature.properties.category;

    let smallIcon = new L.Icon({
      iconSize: this.getIconSize(category),
      iconAnchor: this.getIconAnchor(category),
      popupAnchor: [1, -24],
      iconUrl: iconUrl,
    });

    let address =
      feature.properties.address && feature.properties.address !== ''
        ? feature.properties.address
        : ' ';
    let zonename =
      feature.properties.zone.zonename &&
      feature.properties.zone.zonename !== ''
        ? feature.properties.zone.zonename
        : ' ';
    let code = feature.properties.code;

    let marker = L.marker(centerCoordinates, {
      icon: smallIcon,
      title: address + ' | ' + zonename + ' | ' + code + ' | ' + category,
      objectId: polyLine.options.objectId,
      type: 'Point',
      feature: feature,
    })
      .on('click', () => {
        this.clickOnParkingMarker(marker);
        this.clickOnObject(feature.properties);
      })
      .addTo(this.markerCluster);
  };

  clickOnParcomatPoint = (marker) => {
    const feature = marker.options.feature;
    const isTerminal =
      feature.properties.objecttype === OBJECT_TYPES.TERMINALS ? true : false;
    const isInformationTable = feature.properties.objecttype === OBJECT_TYPES.INFORMATIONTABLE;
    const isChurch = feature.properties.objecttype === OBJECT_TYPES.CHURCH;
    const isMuseum = feature.properties.objecttype === OBJECT_TYPES.MUSEUM;
    const isSight = feature.properties.objecttype === OBJECT_TYPES.SIGHT;
    let icon;
    if (isTerminal) {
      icon = iconUrls.terminal.default;
    } else if(isInformationTable) {
      icon = iconUrls.informationtable.default;
    } else if(isChurch) {
      icon = iconUrls.church.default;
    } else if(isMuseum) {
      icon = iconUrls.museum.default;
    } else if(isSight) {
      icon = iconUrls.sight.default;
    } else {
      icon = iconUrls.parkomat.default;
    }

    this.map.setView(marker.getLatLng(), 20);
    this.resetMarkerIcon();
    this.setIconToMarker(marker, icon, 'big');
    this.currentObject.defaultIconUrl = this.getStylesAndIcon(feature).icon;
    this.currentObject.objectId = marker.options.objectId;
  };

  clickOnParkingLine = (polyLine, iconUrl, category) => {
    this.resetMarkerIcon();
    this.currentObject.lineColor = polyLine.options.color;

    polyLine.setStyle({
      color: '#456cab',
    });

    this.markerCluster.eachLayer((layer) => {
      if (
        layer.options.objectId &&
        layer.options.objectId === polyLine.options.objectId
      ) {
        if (layer.options.type === 'Point') {
          const category = this.getCategory(
            layer.options.feature.properties.category
          );
          this.setIconToMarker(layer, iconUrls.parking[category].orange, 'big');
        }
      }
    });

    this.currentObject.defaultIconUrl = iconUrl;
    this.currentObject.objectId = polyLine.options.objectId;
  };

  clickOnParkingMarker = (marker) => {
    this.map.setView(marker.getLatLng(), 20);
    this.resetMarkerIcon();

    this.markerCluster.eachLayer((layer) => {
      if (
        layer.options.objectId &&
        layer.options.objectId === marker.options.objectId
      ) {
        if (
          layer.options.type === 'PolyLine' ||
          layer.options.type === 'Polygon'
        ) {
          this.currentObject.lineColor = layer.options.color;
          this.currentObject.objectId = layer.options.objectId;
          // layer.setStyle({
          //   color: '#FF6D3D'
          // });
        }
      }
    });
    const category = this.getCategory(
      marker.options.feature.properties.category
    );
    this.setIconToMarker(marker, iconUrls.parking[category].default, 'big');
    this.currentObject.defaultIconUrl = this.getStylesAndIcon(
      marker.options.feature
    ).icon;
  };

  locationFoundEvent = (e) => {
    if (
      e.layer.options.feature.properties.objecttype === OBJECT_TYPES.PARKINGS
    ) {
      this.clickOnParkingMarker(e.layer);
    } else if (
      e.layer.options.feature.properties.objecttype === OBJECT_TYPES.PARKOMATS
    ) {
      this.clickOnParcomatPoint(e.layer);
    }
    this.clickOnObject(e.layer.options.feature.properties);
  };

  resetMarkerIcon = () => {
    this.markerCluster.eachLayer((layer) => {
      if (
        layer.options.objectId &&
        layer.options.objectId === this.currentObject.objectId
      ) {
        if (layer.options.type === 'Point') {
          this.setIconToMarker(
            layer,
            this.currentObject.defaultIconUrl,
            'normal'
          );
        } else if (
          layer.options.type === 'PolyLine' ||
          layer.options.type === 'Polygon'
        ) {
          layer.setStyle({
            color: this.currentObject.lineColor,
          });
        }
      }
    });
  };

  setIconToMarker = (layer, iconUrl, size) => {
    let iconSize = null;
    let iconAnchor = null;
    const category = this.getCategory(
      layer.options.feature.properties.category
    );

    if (size === 'big') {
      iconSize = this.getIconSize(category, size);
      iconAnchor = this.getIconAnchor(category, size);
    } else {
      iconSize = this.getIconSize(category);
      iconAnchor = this.getIconAnchor(category, size);
    }
    layer.setIcon(
      new L.Icon({
        iconSize: iconSize,
        iconAnchor: iconAnchor,
        popupAnchor: [1, -24],
        iconUrl: iconUrl,
      })
    );
  };

  clickOnObject = (properties) => {
    reservationStore.data = properties;
    //UIStore.reservationVisibility = true
    if (UIStore.filterVisibility) {
      UIStore.toggleVisibilityFilter();
    }
    UIStore.reservationVisibility = true;
  };

  toArray = (observableArray) => {
    let array = [];
    observableArray.forEach((item) => {
      array.push(item.toJS());
    });
    return array;
  };

  getCategory(category) {
    const isFree = category === PARK_TYPES.FREE;
    const isForDisabled = category === PARK_TYPES.FORDISABLED;
    const isChurch = category === PARK_TYPES.CHURCH;
    const isMuseum = category === PARK_TYPES.MUSEUM;
    const isSight = category === PARK_TYPES.SIGHT;

    return (isFree || isForDisabled || isChurch || isMuseum || isSight) ? category : PARK_TYPES.DEFAULT;
  }

  getIconAnchor(category, size) {
    switch (category) {
      case PARK_TYPES.FREE:
      case PARK_TYPES.FORDISABLED: {
        return size === 'big' ? [7, 70] : [13, 58];
      }
      default:
        return size === 'big' ? [13, 70] : [20, 55];
    }
  }

  getIconSize(category, size) {
    switch (category) {
      case PARK_TYPES.FREE:
      case PARK_TYPES.FORDISABLED: {
        return size === 'big' ? [60, 70] : [60, 60];
      }
      case PARK_TYPES.SIGHT: {
        return size === 'big' ? [60, 60] : [35, 35];
      }
      case PARK_TYPES.MUSEUM: {
        return size === 'big' ? [60, 60] : [40, 40];
      }
      case PARK_TYPES.CHURCH: {
          return size === 'big' ? [60, 60] : [50, 50];
      }
      default:
        return size === 'big' ? [70, 80] : [70, 70];
    }
  }

  getStylesAndIcon = (feature) => {
    const category = this.getCategory(feature.properties.category);
    const objType = feature.properties.objecttype;
    const isMuseum = objType === OBJECT_TYPES.MUSEUM;
    const isSight = objType === OBJECT_TYPES.SIGHT;
    const isChurch = objType === OBJECT_TYPES.CHURCH;

    let stylesAndIcon = {
      style: styles.default,
      icon: (isMuseum || isSight || isChurch)
        ? iconUrls[objType.toLowerCase()].default
        : iconUrls.parking[category].default,
    };

    if (objType === OBJECT_TYPES.PARKINGS) {
      if (feature.properties.inactive) {
        return {
          style: styles.inactive,
          icon: iconUrls.parking[category].black,
        };
      }

      if (category === PARK_TYPES.FREE) {
        stylesAndIcon = {
          icon: iconUrls.parking[PARK_TYPES.FREE].default,
          style: styles.parking,
        };
      } else {
        const spaces = feature.properties.spaces;
        if (spaces.free === -1) {
          stylesAndIcon = this.getColoredIcon(category, -1);
        } else {
          const takedPlaces = spaces.total - spaces.free;

          const ratio = !takedPlaces ? 0 : (takedPlaces / spaces.total) * 100;

          stylesAndIcon = this.getColoredIcon(category, ratio);
        }
      }
    } else if (objType === OBJECT_TYPES.INFORMATIONTABLE) {
      stylesAndIcon = {
        style: styles.light,
        icon: iconUrls.informationtable.default,
      };
    } else if (isChurch) {
      stylesAndIcon = {
        style: styles.light,
        icon: iconUrls.church.default,
      };
    } else if (isMuseum) {
      stylesAndIcon = {
        style: styles.light,
        icon: iconUrls.museum.default,
      };
    } else if (isSight) {
      stylesAndIcon = {
        style: styles.light,
        icon: iconUrls.sight.default,
      };
    } else {
      const icon =
        objType === OBJECT_TYPES.TERMINALS
          ? iconUrls.terminal.default
          : iconUrls.parkomat.default;

      stylesAndIcon = {
        style: styles.light,
        icon,
      };
    }

    return stylesAndIcon;
  };

  getColoredIcon(category, ratio) {
    switch (true) {
      case ratio < 0:
        return {
          style: styles.default,
          icon: iconUrls.parking[category].default,
        };
      case ratio <= 50:
        return {
          style: styles.light,
          icon: iconUrls.parking[category].green,
        };

      case ratio > 50 && ratio <= 70:
        return {
          style: styles.medium,
          icon: iconUrls.parking[category].yellow,
        };

      case ratio > 70:
        return {
          style: styles.high,
          icon: iconUrls.parking[category].red,
        };

      default:
        break;
    }
  }

  initEvents = () => {
    window.addEventListener('load', this.onResize);
    window.addEventListener('resize', this.onResize);
  };

  removeEvents = () => {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('load', this.onResize);
  };

  initMapStyle = () => {
    UIStore.computeMapStyle();
  };

  initMapCenter() {
    const isMapcenter =
      OptionsStore.hasOwnProperty('mapCenter') && OptionsStore.mapCenter;

    return isMapcenter
      ? [OptionsStore.mapCenter[0], OptionsStore.mapCenter[1]]
      : false;
  }

  onResize = () => {
    UIStore.computeMapStyle();
    if (this.map && this.map._map && this.map._map._container) {
      this.map.invalidateSize();
    }
  };
}

export default MainMap;

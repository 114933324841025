import { observable, action } from 'mobx';

import APIProvider from './APIProvider';
import MapStore from './MapStore';
import LoginStore from './LoginStore';

import moment from 'moment/moment';
import UIStore from './UIStore';
import PaymentStore from './PaymentStore';
import Token from '../utils/Token';
import CityStore from './CityStore';
import { IS_EASY_REGISTRATION } from '../Config';
import SettingsStore from './SettingsStore';

export const defaultTs = {
  mainTs: false,
  numberTs: '',
  formatGrz: 'rus',
  category: 'B',
};

const emptyTs = {
  ГРЗ: 0,
  ОсновноеТС: 0,
  Категория: 0,
  ФорматГРЗ: 0,
  СерияНомерДокумента: 0,
  ТипДокумента: 0,
};

const defaultData = {
  isLoading: false,
  done: false,
  error: false,
};

const registrationType = 'СвидетельствоОРегистрацииТС';

class UserStore {
  USER_TYPE = 'standart';

  GRZ_FORMATS = {
    RUSSIA: 'Российский',
    FOREIGN: 'Иностранный',
  };

  GRZ_FORMATS_EN = {
    RUSSIA: 'rus',
    FOREIGN: 'other',
  };

  @observable hydrated = false;

  @observable token = null;

  @observable restorePasswordToken = null;

  @observable profile = {
    ...defaultData,
    ts: [],
  };

  @observable operations = {
    ...defaultData,
    items: [],
  };

  @observable benefits = {
    items: [],
    ...defaultData,
  };

  @observable documents = [];

  @observable prevTs = [];

  @observable isTsSaving = false;

  @observable settings = {
    ...defaultData,
    reportHistoryOperation: false,
  };

  @action
  hydrateCompleted() {
    this.hydrated = true;
  }

  @action
  chnageMainInfo = async (changedData) => {
    const response = await APIProvider.changeProfile(changedData);
    if (response.status === 'ok') {
      this.profile = {
        ...this.profile,
        ...changedData,
        email: changedData.email || this.profile.email,
      };
    }

    return response;
  };

  @action
  changeNumber = async (telephone) => {
    this.profile = {
      ...this.profile,
      telephone,
    };
  };

  @action
  changeEmail = async (email) => {
    this.profile = {
      ...this.profile,
      email,
    };
  };

  @action
  changePhone = async (telephone) => {
    this.profile = {
      ...this.profile,
      telephone: {
        main: true,
        phone: this.convertPhone(telephone),
      },
    };
  };

  @action
  addDocument = (document) => {
    this.documents.push(document);
  };

  @action
  addTs = (ts) => {
    this.prevTs = [...this.profile.ts];
    return this.addTsV3(ts);
  };

  @action
  addTsV3 = async (ts) => {
    const preparedTs = this.prepareForSaveOne(ts);
    const response = await APIProvider.addCar(preparedTs);
    const isOk = response.status === 'ok';

    if (isOk) {
      this.profile.ts = [
        ...this.profile.ts,
        {
          ...defaultTs,
          ...ts,
          id: this.profile.ts.length,
        },
      ];
    }

    return isOk;
  };

  @action
  editTs = (index, ts) => {
    this.prevTs = [...this.profile.ts];
    return this.editTsV3(index, ts);
  };

  @action
  editTsV3 = async (index, ts) => {
    const preparedNewTs = this.prepareForSaveOneV2(ts);
    const preparedOldTs = this.prepareForSaveOneV2(this.profile.ts[index]);

    const response = await APIProvider.changeCar(preparedNewTs, preparedOldTs);
    const isOk = response.status === 'ok';
    const prevMain = this.getMainTs();
    const prevMainId = this.getTs().findIndex(
      (oldTs) => oldTs.numberTs === prevMain.numberTs
    );

    if (isOk) {
      if (prevMainId !== index && ts.mainTs) {
        this.profile.ts[prevMainId].mainTs = false;
      }
      this.profile.ts[index] = {
        ...defaultTs,
        ...ts,
        id: index,
      };
    }
    return isOk;
  };

  @action
  deleteTs = (index) => {
    this.prevTs = [...this.profile.ts];
    return this.deleteTsV3(index);
  };

  @action
  deleteTsV3 = async (id) => {
    const preparedTs = this.prepareForSaveOneV2(
      this.profile.ts.find((ts) => id === ts.id)
    );
    const response = await APIProvider.removeCar(preparedTs);

    const isOk = response.status === 'ok';
    if (this.profile.hasOwnProperty('ts') && isOk) {
      this.profile.ts = this.profile.ts.filter((transport) => {
        return id !== transport.id;
      });
    }
    return isOk;
  };

  @action
  setToken(token) {
    this.token = token;
    Token.setTokenForCity(CityStore.zone, token, this.USER_TYPE);
  }

  @action
  removeToken() {
    this.setToken(null);
  }

  checkToken(data) {
    if (
      data &&
      data.status &&
      data.status === 'error' &&
      data.errorName &&
      data.errorName === 'TokenExpired' &&
      !UIStore.simpleModal
    ) {
      this.logoutTokenExpired();
    }
  }

  logoutTokenExpired() {
    this.removeToken();
    UIStore.toggleSimpleModal();
  }

  prepareForSave(cars) {
    return cars.map((car) => {
      return this.prepareForSaveOne(car);
    });
  }

  prepareForSaveOne(car) {
    return {
      ГРЗ: car.numberTs || '',
      ОсновноеТС: car.mainTs ? car.mainTs : false,
      Категория: car.category || 'B',
      ФорматГРЗ: car.formatGrz || 'Российский',
      СерияНомерДокумента: car.STSNumber || '',
      ТипДокумента: registrationType,
      VIN: car.vin,
      IMEI: car.imei,
    };
  }

  prepareForSaveOneV2(car) {
    return {
      grz: car.numberTs || '',
      mainTs: car.mainTs ? car.mainTs : false,
      category: car.category || 'B',
      format: car.formatGrz || 'Российский',
      sts: car.STSNumber || '',
      ТипДокумента: registrationType,
      VIN: car.vin,
      IMEI: car.imei,
    };
  }

  parkedVehicle() {
    return this.parkedVehicleV2();
  }

  parkedVehicleV2() {
    const sessions = this.getSessions();

    const parkedNumber = sessions.filter((session) => {
      return session.status !== 'waiting';
    });

    return parkedNumber;
  }

  isParked(vehicleNumber) {
    return this.isParkedV2(vehicleNumber);
  }

  isParkedV2(vehicleNumber) {
    return userStore.parkedVehicleV2().some((session) => {
      return session.transport.numberTs === vehicleNumber.trim();
    });
  }

  isLoggedIn() {
    const token = Token.getTokenForCity(CityStore.zone, this.USER_TYPE);
    return token && token !== null && token !== 'null';
  }

  getProfile() {
    if (this.profile.done)
      return {
        email: this.profile.email,
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        middleName: this.profile.middleName,
        sessions: this.profile.sessions,
        balance: this.profile.balance,
        ts: this.profile.ts,
        telephone: this.profile.telephone,
        paymentsMethods: this.profile.paymentsMethods,
      };

    return {};
  }

  cahngeBalance(to) {
    this.profile = {
      ...this.profile,
      balance: to,
    };
  }

  getFullName() {
    const profile = this.profile;

    return profile.done
      ? [profile.lastName, profile.firstName, profile.middleName].join(' ')
      : '';
  }

  getFirstName() {
    const profile = this.profile;

    return profile.done ? profile.firstName.trim() : '';
  }

  getlastName() {
    const profile = this.profile;

    return profile.done ? profile.lastName.trim() : '';
  }

  getMiddleName() {
    const profile = this.profile;

    return profile.done ? profile.middleName.trim() : '';
  }

  getEmail() {
    const profile = this.profile;

    return profile.done ? profile.email : '';
  }

  getBalance() {
    if (
      this.profile &&
      this.profile.hasOwnProperty('balance') &&
      this.profile.balance
    )
      return this.profile.balance;
    return 0;
  }

  getBenefits() {
    if (this.benefits.hasOwnProperty('items')) {
      return this.benefits.items
        .map((benefit) => {
          // показывать льготы, даже если процент скидки 0
          if (!benefit.type /*|| !benefit.sale*/) return null;

          return benefit;
        })
        .filter((a) => !!a);
    }

    return [];
  }

  getOperations() {
    if (this.operations.hasOwnProperty('items')) {
      return this.operations.items;
    }

    return [];
  }

  getTsOperations() {
    let cars = [];

    this.getOperations().forEach((operation) => {
      const tsNumber = operation.transport;
      if (!this.isCarExist(cars, tsNumber)) {
        cars.push(tsNumber);
      }
    });

    return cars;
  }

  getSortedTsOperations() {
    return this.getTsOperations().sort();
  }

  getOperationsWithPagination(pageSize = 50) {
    const operations = this.getOperations();
    const countOfOperations = operations.length;

    const pageCount = Math.floor(countOfOperations / pageSize);
    const pages = {};

    for (let pageNumber = 1; pageNumber <= pageCount; pageNumber++) {
      const page = [];

      const operationStart = pageSize * pageNumber;
      const operationEnd = operationStart + pageSize;

      for (
        let operationNumber = operationStart;
        operationNumber <= operationEnd;
        operationNumber++
      ) {
        page.push(operations[operationNumber]);
      }

      pages[pageNumber] = page;
    }

    const isLastPage = countOfOperations % pageSize !== 0;
    if (isLastPage) {
      const lastOperations = [];
      const startFrom = pageSize * pageCount;

      for (let i = startFrom; i <= operations.length; i++) {
        lastOperations.push(operations[i]);
      }

      pages[pageCount + 1] = lastOperations;
    }
  }

  @action
  removeOperations() {
    this.operations.items = [];
  }

  isCarExist(cars, carNumber) {
    return cars.some((car) => {
      return car === carNumber;
    });
  }

  getCarByNumber(number) {
    const cars = this.getTs();

    for (let i = 0; i < cars.length; i++) {
      if (cars[i].numberTs.toUpperCase() === number.toUpperCase()) {
        return cars[i];
      }
    }
  }

  getFilteredOperations(filterCondition) {
    return this.getOperations().filter(filterCondition);
  }

  getSortedOperations(sortCondition) {
    return this.getOperations().sort(sortCondition);
  }

  getTelephone() {
    return this.profile.telephone || { phone: '' };
  }

  getAllPhones() {
    if (IS_EASY_REGISTRATION && this.profile.done) {
      return [this.profile.telephone];
    }

    if (this.profile.done && this.profile.telephones)
      return this.profile.telephones;
    return [];
  }

  getDocuments() {
    if (this.profile.hasOwnProperty('documents')) return this.profile.documents;
    return [];
  }

  getTs() {
    if (this.profile.hasOwnProperty('ts')) {
      return this.profile.ts;
    } else return [];
  }

  getSessions() {
    const sessions = this.profile.sessions;
    return this.profile.hasOwnProperty('sessions') ? sessions : [];
  }

  getActiveSessions() {
    if (!SettingsStore.settings.dontUseBalance) {
      return this.getSessions();
    }

    return this.getSessions().filter((session) => session.status === 'active');
  }

  getDefferedSessions() {
    return this.getSessions().filter((session) => session.status === 'waiting');
  }

  getLastSession() {
    const sessions = this.getActiveSessions();
    if (
      this.profile &&
      this.profile.hasOwnProperty('sessions') &&
      sessions.length > 0
    ) {
      const lastSession = sessions[0];
      const currentDate = new Date();

      if (lastSession.endDate < currentDate) return -1;
      return lastSession;
    }

    return -1;
  }

  getCurrentParking = () => {
    if (
      this.profile &&
      this.profile.hasOwnProperty('sessions') &&
      this.profile.sessions.length > 0
    ) {
      const zoneNumber = this.profile.sessions[0].zone;
      return MapStore.getByZoneNumber('parkings', zoneNumber);
    }
  };

  getEndTimeOfparkingProfile = () => {
    if (
      this.profile &&
      this.profile.hasOwnProperty('sessions') &&
      this.profile.sessions.length > 0
    )
      return new Date(this.profile.sessions[0].endDate);

    return -1;
  };

  getStartTimeOfparkingProfile = () => {
    if (
      this.profile &&
      this.profile.hasOwnProperty('sessions') &&
      this.profile.sessions.length > 0
    )
      return new Date(this.profile.sessions[0].startDate);
    return -1;
  };

  @action
  loadBenefitsIfNeeded = async () => {
    if (this.isLoggedIn() && !this.benefits.done && !this.benefits.isLoading) {
      this.benefits.isLoading = true;
      await this.updateBenefits();
    }
  };

  @action
  updateBenefits = async () => {
    this.benefits.isLoading = true;
    this.benefits.done = false;

    const benefits = await APIProvider.getBenefits();
    if (benefits) {
      this.benefits = {
        items: this.normalizeBenefits(benefits),
        isLoading: false,
        done: true,
        error: false,
      };
    } else {
      this.benefits = {
        ...this.benefits,
        isLoading: false,
        done: false,
        error: true,
      };
    }
  };

  @action
  normalizeBenefits(benefits) {
    if (benefits)
      return benefits.map((benefit) => {
        let zone = null;
        if (benefit['Зона'] && Array.isArray(benefit['Зона'])) {
          zone = benefit['Зона'];
        } else {
          if (benefit['Зона']) zone = [benefit['Зона']];
        }

        let transport = {};
        if (benefit['ТС']) {
          transport = {
            mainTs: benefit['ТС']['ОсновноеТС'],
            numberTs: benefit['ТС']['ТранспортноеСредство'],
            formatGrz: benefit['ТС']['ФорматГРЗ'],
            category: benefit['ТС']['КатегорияТС'],
          };
        }

        return {
          variation: benefit['ВидРазрешения'] || null,
          type: benefit['ТипРазрешения'] || null,
          zone: zone || 'all',
          sale: benefit['ПроцентСкидки'] || null,
          transport: transport || null,

          startOf:
            moment(benefit['НачалоДействия']).format('DD/MM/YY HH:mm') || null,

          endOf:
            moment(benefit['ОкончаниеДействия']).format('DD/MM/YY HH:mm') ||
            null,
        };
      });

    return [];
  }

  @action.bound
  loadProfileIfNeeded() {
    if (this.isLoggedIn() && !this.profile.done && !this.profile.isLoading) {
      this.profile.isLoading = true;
      this.updateProfile();
    }
  }

  @action
  updateAll = async () => {
    this.updateProfile();
    this.updateBenefits();
  };

  @action
  updateProfile = async () => {
    this.profile.isLoading = true;
    this.profile.done = false;

    let profile;

    if (IS_EASY_REGISTRATION) {
      if (!SettingsStore.settings.dontUseBalance) {
        profile = await APIProvider.getProfileV5();
      } else {
        profile = await APIProvider.getProfileV6();
      }
    } else {
      profile = await APIProvider.getProfileV4();
    }

    if (!profile.errorName) {
      const normalizedProfile = IS_EASY_REGISTRATION
        ? this.normalizeProfileV4(profile.result)
        : this.normalizeProfileV3(profile);
      this.profile = {
        ...normalizedProfile,
        isLoading: false,
        done: true,
        error: false,
      };
    } else {
      this.profile = {
        ...this.profile,
        isLoading: false,
        done: false,
        error: true,
      };

      LoginStore.logOut();
    }
  };

  @action
  async loadOperationsIfNeeded() {
    if (
      this.isLoggedIn() &&
      !this.operations.done &&
      !this.operations.isLoading
    ) {
      this.operations.isLoading = true;
      this.loadOperations();
    }
  }

  @action async loadOperations() {
    this.operations.isLoading = true;
    this.operations.done = false;

    try {
      const operations = await APIProvider.getOperations();
      if (operations) {
        this.operations = {
          items: this.normalizeOperations(operations),
          isLoading: false,
          done: true,
          error: false,
        };
      }
    } catch (e) {
      this.operations.error = true;
      this.operations.done = false;
      this.operations.isLoading = false;
    }
    this.profile.isLoading = false;
  }

  @action
  setOperationLoadStatuses(loadStatuses) {
    const {
      isLoading = this.defaultData.isLoading,
      done = this.defaultData.done,
      error = this.defaultData.error,
    } = loadStatuses;

    this.operations = {
      ...this.operations,
      isLoading,
      done,
      error,
    };
  }

  @action
  addOperations(operations) {
    if (operations && operations instanceof Object)
      this.operations = {
        ...this.operations,
        items: [
          ...(this.operations.items || []),
          ...this.normalizeOperations(operations),
        ],
      };
  }

  isTransportExist = (tsn) => {
    const vehicles = this.getTs();

    return vehicles.some((vehicle) => {
      return vehicle === tsn;
    });
  };

  normalizeProfileV2(profile) {
    if (profile) {
      return {
        email: profile['Email'],
        firstName: profile['Имя'],
        lastName: profile['Фамилия'],
        middleName: profile['Отчество'],
        sessions: this.normalizeSessionsV2(profile['Сессии']),
        balance: profile['СуммаОстаток'],
        ts: this.normalizeTs(profile['ТС']),
        telephone: profile['Телефон'],
        paymentsMethods: this.normalizePaymentsMethods(
          profile['СпособыОплаты']
        ),
        documents: [],
      };
    }
    return {};
  }

  normalizeProfileV3(profile) {
    if (profile) {
      return {
        ...this.normalizeProfileV2(profile),
        telephone: this.getMainPhone(profile['Телефоны']),
        telephones: this.normalizeAllPhones(profile['Телефоны']),
        // type: 'coop'
      };
    }
    return {};
  }

  normalizeProfileV4(profile) {
    if (profile) {
      return {
        ...this.normalizeProfileV2(profile),
        telephone: {
          phone: profile['Телефон'],
          isMain: true,
        },
        telephones: [{ phone: profile['Телефон'], isMain: true }],
      };
    }
    return {};
  }

  normalizeAllPhones = (phones = []) => {
    const phoness = phones.map((phone) => {
      return {
        phone: phone['Телефон'],
        isMain: phone['Основной'],
      };
    });

    return [...phoness];
  };

  getMainPhone = (phones = null) => {
    if (IS_EASY_REGISTRATION) {
      return this.profile.telephone;
    }

    let mainPhone = null;
    if (phones !== null) {
      phones.forEach((item) => {
        if (item['Основной'] === true) mainPhone = item['Телефон'];
      });
    } else {
      this.profile.telephones.forEach((item) => {
        if (item.isMain === true) mainPhone = item;
      });
    }

    return mainPhone;
  };

  getMainPhoneNumber() {
    const phone = this.getAllPhones().find((elem) => elem.isMain);
    return phone ? phone.phone : '';
  }

  getMainTs() {
    const vehicle = this.getTs().find((vehicle) => vehicle.mainTs);
    return vehicle || this.getTs()[0];
  }

  normalizePaymentsMethods(methods) {
    return methods
      ? methods.map((item) => {
          return {
            nameForShow: item['НаименованиеДляОтображения'],
            name: item['НаименованиеДляВозврата'],
            description: item['ОписаниеНаПортале'],
            isPhoneRequired: item['ТребуетсяНомерТелефона'] || false,
          };
        })
      : [];
  }

  normalizeTs(ts) {
    if (ts) {
      return ts.map((item, index) => {
        return {
          mainTs: item['ОсновноеТС'],
          numberTs: item['ТранспортноеСредство'].replace(' ', ''),
          formatGrz: item['ФорматГРЗ'],
          category: item['КатегорияТС'] || 'B',
          country: item['ФорматГРЗ'] === 'Российский' ? 'rus' : 'other',
          STSNumber: item['Документ'] || item['СерияНомерДокумента'],
          docType: item['ТипДокумента'] || 'СвидетельствоОРегистрацииТС',
          active:
              (item['СтатусУстройства'] === 'Не активно' ||
            !item['СтатусУстройства'])
              ? false
              : true,
          vin: item['VIN'],
          imei: item['IMEI'],
          id: index,
        };
      });
    }

    return [];
  }

  normalizeOperations(operations) {
    if (operations) {
      return operations.map((operation) => {
        return {
          startsOf: operation['ДатаНачалаПарковочнойСессии'],
          endOf: operation['ДатаОкончанияПарковочнойСессии'],
          dateOperation: operation['ДатаОперации'],
          duration: operation['Длительность'],
          type: operation['Операция'],
          zone: operation['Зона'],
          parking:
            operation['Парковка'] && operation['Парковка'] !== null
              ? operation['Парковка']
              : null,
          tariff: operation['Тариф'],
          transport: operation['ТС']['ТранспортноеСредство'],
          resolution: operation['ВидПарковочногоРазрешения'],
          sum: this._parseFloat(operation['СуммаЗаказа']),
          sumSale: this._parseFloat(operation['СуммаСкидки']),
          status: PaymentStore.getPaymentStatus(operation['СтатусЗаказа']),
          orderNumber: operation['НомерЗаказа'],
          driverPhone: operation['ТелефонВодителя'],
          checkFiscal: operation['checkFiscal']
        };
      });
    }

    return [];
  }

  _parseFloat = (number) => {
    return parseFloat(
      String(number)
        .replace(/[^.\d,]/g, '')
        .replace(',', '.')
    );
  };

  normalizeSessions(sessions) {
    if (sessions) {
      return sessions.map((session) => {
        return {
          id: session['Номер'],
          zone: session['Зона'],
          parkingCode: session['Парковка'],
          transport: session['ТС'],
          sum: session['СуммаСписания'],
          duration: session['Длительность'],
          startDate: session['ДатаНачалаПарковочнойСессии'],
          endDate: session['ДатаОкончанияПарковочнойСессии'],
        };
      });
    }
    return [];
  }

  normalizeSessionsV2(sessions) {
    if (sessions) {
      return sessions.map((session) => {
        return {
          id: session['Номер'],
          zone: session['Зона'],
          transport: this.normalizeTsObject(session['ТС']),
          sum: session['СуммаСписания'],
          duration: session['Длительность'],
          startDate: session['ДатаНачалаПарковочнойСессии'],
          endDate: session['ДатаОкончанияПарковочнойСессии'],
          parkingCode: session['Парковка'],
          status: session['Статус'] === 'Действует' ? 'active' : 'waiting',
          amountToPay: session['СуммаСписания']
            ? session['СуммаСписания']
            : null,
        };
      });
    }
    return [];
  }

  normalizeTsObject(ts) {
    return {
      mainTs: ts['ОсновноеТС'],
      numberTs: ts['ТранспортноеСредство'],
      formatGrz: ts['ФорматГРЗ'],
      category: ts['Категория'],
      STSNumber: ts['Документ'] || ts['СерияНомерДокумента'],
      docType: ts['ТипДокумента'] || 'СвидетельствоОРегистрацииТС',
    };
  }

  @action
  clear() {
    this.documents = [];
    this.profile = { ...defaultData };
    this.benefits = { items: [], ...defaultData };
    this.operations = { ...defaultData };
  }

  getGrzFormat(format) {
    return format === this.GRZ_FORMATS.FOREIGN;
  }

  convertPhone(phone) {
    return String(phone)
      .replace(/[^\d+]/g, '')
      .replace(/[+]/g, '');
  }

  @action
  async deletePhone(phone) {
    phone = this.convertPhone(phone);
    this.profile.telephones = this.profile.telephones.filter((item) => {
      return String(item.phone) !== String(phone);
    });
    await APIProvider.deletePhone(phone);
  }

  @action
  addPhone(phone, isMain = null) {
    phone = this.convertPhone(phone);
    this.profile.telephones.push({
      phone,
      isMain: isMain !== null && isMain ? isMain : false,
    });
  }

  @action
  async setMainPhone(phone) {
    phone = this.convertPhone(phone);
    const response = await APIProvider.setMainPhone(phone);
    if (response.status === 'ok') {
      const currentMainPhone = this.getMainPhoneNumber();
      this.profile.telephones = this.profile.telephones.map((telephone) => {
        if (String(telephone.phone) === String(phone)) {
          return {
            ...telephone,
            isMain: true,
          };
        }

        if (String(telephone.phone === String(currentMainPhone))) {
          return {
            ...telephone,
            isMain: false,
          };
        }

        return telephone;
      });
    }
  }

  @action
  async setMainVehicle(number) {
    const currentMainVehicle = this.getMainTs();
    this.profile.ts = this.getTs().map((vehicle) => {
      if (String(vehicle.numberTs) === String(number)) {
        return {
          ...vehicle,
          mainTs: true,
        };
      }

      if (
        currentMainVehicle &&
        String(vehicle.numberTs === String(currentMainVehicle.numberTs))
      ) {
        return {
          ...vehicle,
          mainTs: false,
        };
      }

      return vehicle;
    });
  }

  isMoneyEnough = (needed) => {
    return this.getBalance() > needed;
  };

  @action async loadAccountSettings() {
    this.settings.isLoading = true;
    this.settings.done = false;
    try {
      const response = await APIProvider.getAccountSettings();
      if (response) {
        this.settings = {
          ...response,
          isLoading: false,
          done: true,
          error: false,
        };
      }
    } catch (e) {
      console.error(e);
      this.settings = {
        ...this.settings,
        isLoading: false,
        done: false,
        error: true,
      }
    }
  }

  @action changeAccountSettings = async (changedData) => {
    this.settings.isLoading = true;
    const response = await APIProvider.changeAccountSettings(changedData);
    if (response.status === 'ok') {
      this.settings = {
        ...this.settings,
        [changedData.name]: changedData.value,
      };
    }
    this.settings.isLoading = false;
    return response;
  };
}

const userStore = new UserStore();

// hydrate('userStore', userStore, async () => {
//   await userStore.hydrateCompleted();
// });

export default userStore;

import React, { Component } from "react";

import Payment from "../../Components/ProfileComponents/Payment";

import AbonementCardContainer from "../AbonementCardContainer";
import { AbonementContainer as Container } from "../../Components/AbonementCard";
import IsLoading from "../ProfileContainer/Abonements/Loading";

import AbonementStore from "../../Stores/AbonementStore";
import UIStore from "../../Stores/UIStore";

import { observable } from "mobx";
import { observer } from "mobx-react";
import userStore from "../../Stores/UserStore";

@observer
class AbonementsContainer extends Component {
  @observable
  subscriptionCode = "";

  @observable
  cost = "";

  @observable
  subscriptionPermit = "";

  @observable
  subscriptionPermitType = "";

  componentDidMount() {
    AbonementStore.loadSubscriptionsIfNeeded();
  }

  UNSAFE_componentWillReceiveProps() {
    AbonementStore.loadSubscriptionsIfNeeded();
  }

  setPaymentData(data) {
    this.cost = data.cost;
    this.subscriptionCode = data.parkingPermitCode;
    this.subscriptionPermit = data.parkingPermit;
    this.subscriptionPermitType = data.parkingPermitType;
  }

  resetPaymentData() {
    this.cost = "";
    this.subscriptionCode = "";
    this.subscriptionPermit = "";
    this.subscriptionPermitType = "";
  }

  getPaymentData() {
    return {
      codeSubscription: this.subscriptionCode,
      typeSubscription: this.subscriptionPermitType,
      cost: this.cost
    };
  }

  handleAbonementCardClick = paymentData => {
    this.setPaymentData(paymentData);
  };

  handleModalExit() {
    this.resetPaymentData();
    UIStore.closeSubscriptionModal();
  }

  renderCards() {
    const { t } = this.props;
    const abonements = AbonementStore.abonements;
    if (abonements.length > 0)
      return AbonementStore.abonements.map((element, key) => (
          /* todo: проперти zones={element.zone} больше не испольтзуется, необходимо его выпилить */
        <AbonementCardContainer
          parkingPermit={element.parkingPermit}
          parkingPermitCode={element.parkingPermitCode}
          parkingPermitType={element.parkingPermitType}
          parkingPermitDesc={element.parkingPermitDesc}
          cost={element.cost}
          zones={element.zone}
          onClick={this.handleAbonementCardClick}
          key={key}
        />
      ));

    return <span>{t("abonements:errors:subscriptionsIsEmpty")}</span>;
  }

  render() {
    return (
      <>
        {!userStore.profile.isLoading ? (
          <Payment
            isOpen={UIStore.modals.subscriptionModal}
            onSubmit={UIStore.closeSubscriptionModal}
            onExit={UIStore.closeSubscriptionModal}
            data={{ ...this.getPaymentData() }}
            actionType="subscription"
          />
        ) : null}
        <IsLoading isLoading={AbonementStore.status.isLoading}>
          <Container empty={AbonementStore.abonements.length === 0}>
            {this.renderCards()}
          </Container>
        </IsLoading>
      </>
    );
  }
}

export default AbonementsContainer;
